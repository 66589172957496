@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_variables";
@import "./src/app/styles/mixins";
@import "./src/app/styles/variables";
@import "./src/app/styles/global";
@import "./src/app/styles/common";

.really-custom .tooltip-inner {
  background-color: #69b8be;
  font-size: 1.2rem;
  opacity: 1 !important;
}
.really-custom .arrow::before {
  border-top-color: #69b8be;
  opacity: 1 !important;
}

// Tooltip on search page

.search-tooltip .tooltip-inner {
  background-color: white;
  color: black;
  font-family: "Bebas Neue Regular";
  font-weight: 200;
  border: 2px solid #69b8be;
  font-size: 0.9rem;
  opacity: 1 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  max-width: 210px;
}
.search-tooltip .arrow::before {
  border-top-color: transparent;
  opacity: 0 !important;
}

// Tooltip on info page (lift section)

.lifts-tooltip .tooltip-inner {
  background-color: white;
  color: black;
  font-family: "Bebas Neue Regular";
  font-weight: 200;
  border: 2px solid #69b8be;
  font-size: 0.9rem;
  opacity: 1 !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left;
  max-width: 500px;
}
.lifts-tooltip .arrow::before {
  border-top-color: transparent;
  opacity: 0 !important;
}

.tooltip.show {
  opacity: 1 !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .search-tooltip {
    display: none !important;
  }
}
